import { ChangeDetectionStrategy, Component, computed, effect, input, signal } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { I18nGeneralPipesModule } from '@caronsale/frontend-pipes';
import { EnzoHeadlineComponent, EnzoIconComponent, EnzoTextComponent } from '@enzo';
import { EFrequentBuyerStatusTypeNames } from '@cosTypes';

import { statusToColorIconMap } from '@cosBuyer/loyalty/partials/configs';
import { fadeOut } from '@cos/partials/animations';

import { animationTimers } from '../state/loyalty.service';

@Component({
  standalone: true,
  selector: 'app-loyalty-icon',
  template: `
    <span [class]="currentStatus()"></span>
    <enzo-icon [icon]="statusToColorIconMap[currentStatus()].icon" [class.animation]="upgradeAnimationConfig()?.display" />
    @if (upgradeAnimationConfig()?.display) {
      <div @fadeOut>
        <enzo-headline level="2" color="white">{{ 'buyer.loyalty-program.status.' + currentStatus() | translate }}</enzo-headline>
        <enzo-text kind="body-bold" color="white">{{ 'buyer.loyalty-program.promoted-congrats' | translate }}</enzo-text>
        <enzo-text kind="emphasis-label" color="white">
          {{ 'buyer.loyalty-program.since' | translate: { date: upgradeAnimationConfig().currentStatusSince | i18nDate } }}
        </enzo-text>
      </div>
    }
  `,
  styleUrls: ['./loyalty-icon.component.scss'],
  imports: [EnzoIconComponent, EnzoHeadlineComponent, EnzoTextComponent, TranslateModule, I18nGeneralPipesModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeOut(`${animationTimers.slideOut}ms linear`)],
  host: {
    '[class.animation]': 'animationTrigger()',
    '[style.--width]': 'width()',
    '[style.--width-proportion]': 'widthProportion()',
    '[style.--total-animation-time]': 'totalAnimationTime',
    '[style.--slide-out-animation-time]': 'slideOutAnimationTime',
  },
})
export class LoyaltyIconComponent {
  public currentStatus = input.required<EFrequentBuyerStatusTypeNames>();
  public upgradeAnimationConfig = input<{ display: boolean; currentStatusSince: string; inactiveWidth: number; activeWidth: number }>();
  public statusToColorIconMap = statusToColorIconMap;

  public width = computed(() => {
    const animation = this.upgradeAnimationConfig();
    return animation ? (animation.display ? animation.activeWidth : animation.inactiveWidth) + 'px' : 'auto';
  });

  public widthProportion = computed(() => {
    const animation = this.upgradeAnimationConfig();
    if (!animation) return;
    return (animation.activeWidth - animation.inactiveWidth) / 2 + 'px';
  });

  public animationTrigger = signal(false);
  public totalAnimationTime = `${animationTimers.total}ms`;
  public slideOutAnimationTime = `${animationTimers.slideOut}ms`;

  public constructor() {
    effect(
      () => {
        if (this.upgradeAnimationConfig()?.display) {
          this.animationTrigger.set(true);
          setTimeout(() => this.animationTrigger.set(false), animationTimers.total);
        }
      },
      { allowSignalWrites: true },
    );
  }
}
